import React, { useState, useEffect } from 'react';
import '../css/Footer.css' 

function Footer() {
    const [jsonData, setJsonData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let storedCulture = localStorage.getItem('culture');
                if(storedCulture == null) { 
                    storedCulture = 'en_UK';
                }
                const response = await import(`../../public/culture/${storedCulture}.json`);
                setJsonData(response.default);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    return (
        <div>
             {jsonData ? (
                <div className="footer">
                    <p>&copy; {new Date().getFullYear()} Nippon Club London  | {jsonData.footer.developed_by} <a href="https://www.linkedin.com/in/marco-conti-aba1aa13a/" target="_blank" rel="noopener noreferrer">{jsonData.footer.developer_name}</a></p>
                </div>
                ) : (
                <div>Loading...</div>
            )}
        </div>

    );
}

export default Footer;
