import React, { useState, useEffect } from 'react';
import '../css/Description.css';

const Description = () => {

    const [jsonData, setJsonData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let storedCulture = localStorage.getItem('culture');
                if(storedCulture == null) { 
                    //load uk as default
                    storedCulture = 'en_UK';
                }
                const response = await import(`../../public/culture/${storedCulture}.json`);
                setJsonData(response.default);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    return (
        <div>
            {jsonData ? (
                <div className="description">
                    <h1>{jsonData.description.title}</h1>
                    <p>{jsonData.description.text}</p>
                </div>
            ) : (
                <div>Loading...</div>
            )}
        </div>
    );

};

export default Description;
