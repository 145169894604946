import React, { useState, useEffect } from 'react';
import '../css/Courses.css';

const Courses = () => {

    const [jsonData, setJsonData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let storedCulture = localStorage.getItem('culture');
                if(storedCulture == null) { 
                    //load uk as default
                    storedCulture = 'en_UK';
                }
                const response = await import(`../../public/culture/${storedCulture}.json`);
                setJsonData(response.default);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    // Group courses by coach value
    const coursesByCoach = {};
    if (jsonData && jsonData.courses) {
        jsonData.courses.forEach(course => {
            const coachValue = course.coach.value;
            if (!coursesByCoach[coachValue]) {
                coursesByCoach[coachValue] = [];
            }
            coursesByCoach[coachValue].push(course);
        });
    }

    return (
        <div className="courses-page">
            {jsonData ? (
                <div className="cards-container">
                    {Object.keys(coursesByCoach).map(coachValue => (
                        <div key={coachValue} className="card">
                            <h2>{coachValue}</h2>
                            {coursesByCoach[coachValue].map((course, index) => (
                                <div key={index} className="course">
                                    <h3>{course.title}</h3>
                                    <p className="description">{course.description}</p>
                                    <div className="details">
                                        {course.price.value !== "" ? <p className="price">{course.price.title}: {course.price.value}</p> : null}
                                        {course.hours.value !== "" ? <p className="hours">{course.hours.title}: {course.hours.value}</p> : null}
                                        {course.days.value !== "" ?  <p className="days">{course.days.title}: {course.days.value}</p> : null}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            ) : (
                <div>Loading...</div>
            )}
        </div>
    );


};
export default Courses;
