import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import '../css/Contact.css';
import waicon from '../images/whatsapp_icon.png';
import gmailicon from '../images/gmail_icon.png';
import fbicon from '../images/facebook_icon.png';
import igicon from '../images/instagram_icon.png';

const ContactMe = () => {
  const defaultCenter = { lat: 51.41739, lng: -0.15392 };
  const defaultZoom = 15;

  const [jsonData, setJsonData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let storedCulture = localStorage.getItem('culture');
        if (storedCulture == null) {
          storedCulture = 'en_UK';
        }
        const response = await import(`../../public/culture/${storedCulture}.json`);
        setJsonData(response.default);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const handleWAButtonClick = (event) => {
    event.preventDefault(); 
    const phoneNumber = jsonData ? jsonData.personalinfo.phonenumber : '';
    if (phoneNumber) {
        const whatsappURL = `https://wa.me/${phoneNumber}?text=Hello%21`;
        const whatsappAnchor = document.createElement('a');
        whatsappAnchor.setAttribute('href', whatsappURL);
        whatsappAnchor.setAttribute('target', '_blank');
        whatsappAnchor.click();
    } else {
        console.error('Whatsapp phone number is not available.');
    }
};

const handleGmailClick = (event) => {
  event.preventDefault(); 
  const recipientEmail = jsonData ? jsonData.personalinfo.email : '';
  if (recipientEmail) {
      const subject = encodeURIComponent('JudoWebSite');
      const mailtoURL = `mailto:${recipientEmail}?subject=${subject}`;
      const mailtoAnchor = document.createElement('a');
      mailtoAnchor.setAttribute('href', mailtoURL);
      mailtoAnchor.click();
  } else {
      console.error('Gmail recipient is not available.');
  }
};

const handleFBButtonClick = (event) => {
  event.preventDefault(); 
  const fb = jsonData ? jsonData.personalinfo.facebook : '';
  if (fb) {
      const fbAnchor = document.createElement('a');
      fbAnchor.setAttribute('href', fb);
      fbAnchor.setAttribute('target', '_blank');
      fbAnchor.click();
  } else {
      console.error('Facebook URL is not available.');
  }
};

const handleIGButtonClick = (event) => {
  event.preventDefault(); 
  const ig = jsonData ? jsonData.personalinfo.instagram : '';
  if (ig) {
      const igAnchor = document.createElement('a');
      igAnchor.setAttribute('href', ig);
      igAnchor.setAttribute('target', '_blank');
      igAnchor.click();
  } else {
      console.error('Instagram URL is not available.');
  }
};

const GoogleMap = () => (
    <iframe
        width="100%"
        height="400vh"
        loading="lazy"
        allowfullscreen
        referrerpolicy="no-referrer-when-downgrade"
        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCaljwtKaUWCCoStafdktcFKWwozEltCgs&q=51+Woodland+Way%2C+Mitcham+CR4+2DZ">
    </iframe>
);

const Socials = () => (
    <div className="social-button">
      <div className="wa-button">
          <img src={waicon} alt="WhatsApp" className="icon" onClick={(event) => handleWAButtonClick(event)}/>
          <span className="button-text">WhatsApp</span>
      </div>
      <div className="gmail-button">
          <img src={gmailicon} alt="Gmail" className="icon" onClick={(event) => handleGmailClick(event)}/>
          <span className="button-text">Gmail</span>
      </div>
      <div className="ig-button">
          <img src={igicon} alt="Instagram" className="icon" onClick={(event) => handleIGButtonClick(event)}/>
          <span className="button-text">Instagram</span>
      </div>
      <div className="fb-button">
          <img src={fbicon} alt="Facebook" className="icon"  onClick={(event) => handleFBButtonClick(event)}/>
          <span className="button-text">Facebook</span>
      </div>
    
    </div>
  );

  return (
    <div className="contact-page">
       <GoogleMap />
       <Socials />
    </div>
  );
};

export default ContactMe;
