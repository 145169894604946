import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './components/NavBar';
import DropdownMenu from './components/DropdownMenu';
import Footer from './components/Footer';
import Home from './components/Home';
import Courses from './components/Courses';
import Contact from './components/Contact';
import './css/App.css';

function App() {
    return (
        <Router>
            <div className="App">
                <NavBar />
                <div className="main-content">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/courses" element={<Courses />} />
                        <Route path="/contact" element={<Contact />} /> 
                    </Routes>
                </div>
                <Footer />
            </div>
        </Router>
    );
}

export default App;
