// SliderWithMouseScroll.js
import React, { useRef, useState } from 'react';
import '../css/Slider.css';

const Slider = ({ images }) => {
    const [scrollX, setScrollX] = useState(0);
    const sliderRef = useRef(null);

    const handleScroll = (event) => {
        const delta = Math.max(-1, Math.min(1, (event.nativeEvent.deltaY || -event.nativeEvent.detail)));
        sliderRef.current.scrollLeft += delta * 100;
    };

    return (
        <div className="slider-container" onWheel={handleScroll} ref={sliderRef}>
            {images.map((image, index) => (
                <div key={index} className="slide">
                    <img src={image} alt={`slide-${index}`} />
                </div>
            ))}
        </div>
    );
};

export default Slider;
