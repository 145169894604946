import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom'; // Import NavLink from react-router-dom
import judoLogo from '../images/logo.png';
import itaFlag from '../images/italy_flag.png';
import ukFlag from '../images/unitedkingdom_flag.png';
import '../css/NavBar.css';
import DropdownMenu from './DropdownMenu'


const NavBar = () => {
    const [jsonData, setJsonData] = useState(null);
    const [currentLanguage, setCurrentLanguage] = useState(() => {
        return localStorage.getItem('culture') || 'en-UK';
    });

    const handleClickFlag = (language) => {
        setCurrentLanguage(language);
        localStorage.setItem('culture', language);
        window.location.reload();
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                let storedCulture = localStorage.getItem('culture');
                if(storedCulture == null) { 
                    //load uk as default
                    storedCulture = 'en_UK';
                }
                const response = await import(`../../public/culture/${storedCulture}.json`);
                setJsonData(response.default);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    return (
        <nav className="navbar">
            <div className="navbar-container">
                <div className="logo-container">
                    <img src={judoLogo} alt="Logo" className="logo" />
                </div>
               
                
                <DropdownMenu />
                
                <div className="culture-flags">
                    <img src={itaFlag} alt="flag" onClick={(e) => handleClickFlag("it_IT")}/>
                    <img src={ukFlag} alt="flag" onClick={(e) => handleClickFlag("en_UK")}/>
                </div>
         
            </div>

        </nav>
    );
};

export default NavBar;
