import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom'; // Import NavLink from react-router-dom
import '../css/DropdownMenu.css';

function DropdownMenu() {
    const [jsonData, setJsonData] = useState(null);
    const [currentLanguage, setCurrentLanguage] = useState(() => {
        return localStorage.getItem('culture') || 'en-UK';
    });

     useEffect(() => {
        const fetchData = async () => {
            try {
                let storedCulture = localStorage.getItem('culture');
                if(storedCulture == null) { 
                    //load uk as default
                    storedCulture = 'en_UK';
                }
                const response = await import(`../../public/culture/${storedCulture}.json`);
                setJsonData(response.default);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };

  return (
    <div>
     <button  className="menu-button" onClick={toggleMenu}>&#9776;</button>
        {isMenuOpen && (
                <div className="dropdown-menu">
                    <ul>
                        <li onClick={toggleMenu} ><NavLink exact to="/">{jsonData ? jsonData.navbar.home : ''}</NavLink></li>
                        <li onClick={toggleMenu} ><NavLink to="/courses">{jsonData ? jsonData.navbar.courses : ''}</NavLink></li>
                        <li onClick={toggleMenu} ><NavLink to="/contact">{jsonData ? jsonData.navbar.contact : ''}</NavLink></li>
                    </ul>
                </div>
        )}
    </div>
  );
}

export default DropdownMenu;
